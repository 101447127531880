//  Core
import Mmenu        from "mmenu-js/dist/core/oncanvas/mmenu.oncanvas";

//  Core add-ons
import offcanvas    from "mmenu-js/dist/core/offcanvas/mmenu.offcanvas";
import screenReader from "mmenu-js/dist/core/screenreader/mmenu.screenreader";
import scrollBugFix from "mmenu-js/dist/core/scrollbugfix/mmenu.scrollbugfix";

//  Add-ons
import autoHeight from 'mmenu-js/dist/addons/autoheight/mmenu.autoheight';
import backButton from 'mmenu-js/dist/addons/backbutton/mmenu.backbutton';
import columns from 'mmenu-js/dist/addons/columns/mmenu.columns';
import counters from 'mmenu-js/dist/addons/counters/mmenu.counters';
import dividers from 'mmenu-js/dist/addons/dividers/mmenu.dividers';
import drag from 'mmenu-js/dist/addons/drag/mmenu.drag';
import dropdown from 'mmenu-js/dist/addons/dropdown/mmenu.dropdown';
import fixedElements from 'mmenu-js/dist/addons/fixedelements/mmenu.fixedelements';
import iconbar from 'mmenu-js/dist/addons/iconbar/mmenu.iconbar';
import iconPanels from 'mmenu-js/dist/addons/iconpanels/mmenu.iconpanels';
import keyboardNavigation from 'mmenu-js/dist/addons/keyboardnavigation/mmenu.keyboardnavigation';
import lazySubmenus from 'mmenu-js/dist/addons/lazysubmenus/mmenu.lazysubmenus';
import navbars from 'mmenu-js/dist/addons/navbars/mmenu.navbars';
import pageScroll from 'mmenu-js/dist/addons/pagescroll/mmenu.pagescroll';
import searchfield from 'mmenu-js/dist/addons/searchfield/mmenu.searchfield';
import sectionIndexer from 'mmenu-js/dist/addons/sectionindexer/mmenu.sectionindexer';
import setSelected from 'mmenu-js/dist/addons/setselected/mmenu.setselected';
import sidebar from 'mmenu-js/dist/addons/sidebar/mmenu.sidebar';
import toggles from 'mmenu-js/dist/addons/toggles/mmenu.toggles';

//  Wrappers
import bootstrap    from "mmenu-js/dist/wrappers/bootstrap/mmenu.bootstrap";

Mmenu.addons = {
    //	Core add-ons
    offcanvas,
    screenReader,
    scrollBugFix,

    //	Add-ons
    autoHeight,
    backButton,
    columns,
    counters,
    dividers,
    drag,
    dropdown,
    fixedElements,
    iconbar,
    iconPanels,
    keyboardNavigation,
    lazySubmenus,
    navbars,
    pageScroll,
    searchfield,
    sectionIndexer,
    setSelected,
    sidebar,
    toggles
};

//	Wrappers
Mmenu.wrappers = {
    bootstrap
};

//	Global namespace
window.Mmenu = Mmenu;

//	jQuery plugin
(function($) {
    if ($) {
        $.fn.mmenu = function(options, configs) {
            var $result = $();

            this.each(function(e, element) {
                //	Don't proceed if the element already is a mmenu.
                if (element['mmApi']) {
                    return;
                }

                let menu = new Mmenu(element, options, configs),
                    $menu = $(menu.node.menu);

                //	Store the API.
                $menu.data('mmenu', menu.API);

                $result = $result.add($menu);
            });

            return $result;
        };
    }
})(window.jQuery || window.Zepto || null);

export class MobileMenu {

    constructor(selector, options, config) {

        this.selectors = {
            panel : "#mobile-menu",
            button : "button[data-togle=\"mobile-menu\"]"
        };

        this.defaultsOptions = {
            // DEFAULT OPTIONS
            navbar: {
                title: "Навигация"
            },

            //
            // counters: true,
            "extensions": [
                "pagedim-black"
            ],

            // slidingSubmenus: false,

        };

        this.defaultConfig = {
            // DEFAULT CONFIG
            classNames: {
                selected: "active",
                // panel: '#mobileMenuPage'
            },
            language: 'ru',

            offCanvas: {
                pageSelector: "#mobileMenuPage"
            },


            // "navbars": [
            //     {
            //         "position": "bottom",
            //         "content": [
            //             "<div>Добро пожаловать!</div>"
            //         ]
            //     },
            //     {
            //         "position": "bottom",
            //         "content": [
            //             "<a class='fa fa-envelope' href='#/'></a>",
            //             "<a class='fa fa-twitter' href='#/'></a>",
            //             "<a class='fa fa-facebook' href='#/'></a>"
            //         ]
            //     }
            // ]
        };

        this.options = $.extend({}, this.defaultsOptions, options);
        this.config = $.extend({}, this.defaultConfig, config);

        this.panelSelector = selector || this.selectors.panel;

        this.windowWidth = $(window).width();
    }

    resize() {
        this.windowWidth = $(window).width();
        this.init();
    }

    init() {

        if (this.windowWidth < 1200) {

            // console.log('INIT Mmenu');

            const menu = new Mmenu( this.panelSelector, this.options, this.config);

            const api = menu.API;

            const panel = document.querySelector( this.panelSelector);
            // api.openPanel( panel );

            // const $menuToggle = document.querySelector( this.selector.button);
            const $menuToggle = $(this.selectors.button);

            $menuToggle.on( "click", function() {
                api.open();
            });


            // $(document).on('click', this.selector.button, function () {
            //     // api.openPanel( panel );
            //     api.open();
            // });


            // API.bind( "opened", function() {
            //
            //     setTimeout(function() {
            //         $icon.addClass( "is-active" );
            //     }, 100);
            //
            //     $icon.on( "click", function() {
            //         API.close();
            //     });
            //
            // });

            // API.bind( "closed", function() {
            //
            //     setTimeout(function() {
            //
            //         $icon.removeClass( "is-active" );
            //
            //     }, 100);
            //
            //     $icon.on( "click", function() {
            //
            //         API.open();
            //
            //     });
            //
            // });
        }

    }
}